import React, { Component } from 'react'; // destructuring
import Avatar from '@material-ui/core/Avatar';
import './About.scss';

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import Me from './me.jpg';
import SvgCleancode from './SvgCleancode';
import SvgUxuidesign from './SvgUxuidesign';
import SvgCertificate from './SvgCertificate';
import { FaGithub, FaMedium } from 'react-icons/fa';
import { IconContext } from "react-icons";
// import SvgInstagram from '../Footer/SvgInstagram';
import SvgLinkedin from '../Footer/SvgLinkedin';
import SVGYoutuber from '../Footer/SVGYoutuber';
// import SvgFacebook from '../Footer/SvgFacebook';
import SVGBehance from '../Footer/SVGBehance';
import SVGEdu from './SvgEducation';
import SVGTwitter from '../Footer/SVGTwitter';

/*
const obj = { foo: 3, bar: 5 };
const { foo, bar } = obj; // destructuring
const foo = obj.foo;
const bar = obj.bar;
const { foo: xyz } = obj; // xyz === 3 // aliasing

// mymodule.js
export const foo = 5 // named export
export default 5 // we did not specify the name // default export
// pretend we are importing mymodule.js
import { foo } from './mymodule'; // foo === 5 // named imports
import whatever from './mymodule'; // whatever === 5 // default import // we can use any name we want to get the default export
import whatever, { foo } from './mymodule';
import { foo as bar } from './mymodule'; // bar === 5 // aliasing (alias) , You can name as bar 
*/

const styles = {
    avatar: {
        margin: 10,
      },
    bigAvatar: {
        width: 250,
        height: 250,
    },
    center: {
        margin: "0 auto", 
    },
};

class About extends Component {
    render() {
        const { classes } =  this.props
        return (
            <div className="AboutMeDiv">
                <div className="AboutMe">
                    <div className="About-Avatar">
                        <div className="Avatar-Frame">
                            <h1 className="marginBottom">About Me</h1>
                            <Avatar
                                    id="avatar"
                                    alt="Ting Chieh Lin"
                                    src= {Me}
                                    className={`${classes.bigAvatar} ${classes.avatar} ${classes.center}`}
                            />
                        </div>
                        <div className="SocialIcon">
                            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/jaylinxr/"><SvgLinkedin/></a>
                            <IconContext.Provider value={{ color: "black",size: "1.5em"}}>
                                <a target="_blank" rel="noopener noreferrer" href="https://github.com/TingChiehLin"><FaGithub/></a>
                            </IconContext.Provider>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.behance.net/jaylinxr"><SVGBehance/></a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UC1rMgKD4Rn-7aVcymjlvhfQ/"><SVGYoutuber/></a>
                            {/*<IconContext.Provider value={{ color: "black",size: "1.5em"}}>*/}
                            {/*    <a className="socailIcon" target="_blank" rel="noopener noreferrer" href="https://medium.com/@JayLinXR"><FaMedium/></a>*/}
                            {/*</IconContext.Provider>*/}
                            {/* <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/cooloojayoo"><SVGTwitter/></a> */}
                            {/* <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/lintingchieh_jason/"><SvgInstagram/></a> */}
                            {/* <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/tingchang.lin.1"><SvgFacebook/></a> */}
                        </div>  
                    </div>
                    <div className="About-Intro">
                        <div className="About-Title">
                            {/* <h2>XR Enthusiast & UX / UI Developer | Designer 🤠. I design a User-Friendly Experience and Interface into Code.</h2> */}
                            <p>I am living in Melbourne and born in Taiwan 🇹🇼 .
                                I am passionated about design and programming since I studied a bachelor degree. Building and developing enable me to learn and explore a new field.
                                To create a product, I consider to have compelling <span className="fontBlod">User Experience</span> based on user research and iterative feedbacks.
                                I am also an autodidact and crazy about coding so I have experienced <span className="fontBlod">Web / XR / Moblie</span> applications and interactive projects throughout my life.</p>
                        </div>
                        <hr></hr>
                        <div className="About-Skill">
                            <div className="About-UX">
                                <div className="About-Skill-pic">
                                    <SvgUxuidesign />
                                </div>
                                <p style={{'marginTop':'-0.5rem'}}>- Specialising in Prototyping, Wireframe, User Flow, A & B Testing, StoryBoard using <span className="fontBlod">Sketch / Figma / Adobe Creative Suite / Affinity Suite/ InVision / Axure RP 10</span>
                                {/* <br/>
                                • 3D Design Tool with <span className="fontBlod">Cinema 4D</span> and <span className="fontBlod">Blender</span> */}
                                <br/>
                                - Video Editing with <span className="fontBlod">Final Cut Pro</span> and <span className="fontBlod">Loom</span>
                                </p>
                            </div>
                            <div className="About-Code">
                                <div className="About-Skill-pic-code"> 
                                    <SvgCleancode />
                                </div>
                                <p>- High proficiency with Front-End development by <span className="fontBlod"></span><span className="fontBlod">TypeScript/ React / Redux / React Native / Nextjs 14/ TailwindCSS / Sass / Vite/ Python</span><br/>
                                - Server Side with Back-End development by <span className="fontBlod"></span><span className="fontBlod">Node / Express/ SQL/ SQLAlchemy</span><br/>
                                - <span className="fontBlod">XR</span> immersive Experience Design, Development with a deep focus on effective interactionwith <span className="fontBlod">Unity3D</span><br/>
                                {/* & Unreal Engine 4 */}
                                {/*• Using <span className="fontBlod">Swift 5.2</span> and <span className="fontBlod">Xcode 12 with SwiftUI</span> develop<span className="fontBlod"> Mobile </span>applications*/}
                                </p>
                            </div>
                            <div className="About-Edu">
                                <div className="About-Edu-pic">
                                    <SVGEdu/>
                                </div>
                                <p>
                                {/* <span className="fontBlod">- New York University</span> | Online Education | <span className="fontBlod">UX Design Principles For AR & VR</span> | 2020<br/> */}
                                {/* <span className="fontBlod">- New York University</span> | Online Education | <span className="fontBlod">UX Design Principles For AR & VR</span> | 2020<br/> */}
                                    <span className="fontBlod">- Academy Xi</span> | Software Engineer Bootcamp in <span className="fontBlod">Software Engineering: Transform</span> | 2024 <br/>
                                    <span className="fontBlod">- Monash University</span> | Master of Design specialised in <span className="fontBlod">Interaction design</span> | 2019 - 2020<br/>
                                    {/* <span className="fontBlod">- RMIT University</span> | Master of Animation, Games and Interactivity | <span className="fontBlod">Game and Interactive Media Design</span> | 2018<br/> */}
                                    <span className="fontBlod">- CareerFoundry</span> | Mentored UI Design Course in terms of <span className="fontBlod">UI Designer</span> | 2017 - 2018
                                </p>
                            </div>
                            {/* <div className="About-Certificate">
                                <div className="About-Certificate-pic">
                                    <SvgCertificate/>
                                </div>
                                <p>
                                    <span className="fontBlod">- Udacity</span> | <span className="fontBlod">Full Stack JavaScript Developer Nanodegree Degree</span> | 2021<br/>
                                    <span className="fontBlod">- Udacity</span> | <span className="fontBlod">Front End Web & Intermediate JavaScript & React Developer Nanodegree Degree</span> | 2020 - 2021<br/>
                                    <span className="fontBlod">- The Interaction Design Foundation</span> | Membership Certificate in<span className="fontBlod"> Design for Augmented and Virtual Reality</span> | 2020<br/>
                                    <span className="fontBlod">- edX</span> | Design Thinking in terms of<span className="fontBlod"> Empathizing to Understand the Problem</span> | 2019<br/>
                                    <span className="fontBlod">- CareerFoundry</span> | Mentored UI Design Course in terms of <span className="fontBlod">UI Designer</span> | 2017 - 2018
                                </p>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


About.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  

export default withStyles(styles)(About);



